import React, { useState } from 'react'
import videojs from 'video.js'
import 'videojs-contrib-ads/dist/videojs-contrib-ads.css'
import qualityLevels from 'videojs-contrib-quality-levels'
import hlsQualitySelector from 'videojs-hls-quality-selector'
import './videoJsStyle.css'
import './customVideoJs.css'
import ads from 'videojs-contrib-ads'
// import { AdSecondsConvert, getAdUrl } from '../../utils/helper'
// import { AppContext } from '../../context/app'
import { isEmpty } from 'lodash'
import { useInterval } from '../../hooks/useInterval'
import { convertVideoSecondstoMintues } from './playerHelper'
import { isMobile } from 'react-device-detect'
import SeekBar from './controlBarComponents/seekbar'
import VolumeBar from './controlBarComponents/volumeBar'
import PlayMenu from './controlBarComponents/playMenu'
import SettingBar from './controlBarComponents/settingBar'
import HeaderBar from './headerBar/headerBar'
import DisplayControls from './screenComponents/displayControls'
import PlayerLogo from './screenComponents/playerLogo'
import PlayerWatermark from './screenComponents/playerWatermark'
import { useTranslation } from 'react-i18next'
import { AdSecondsConvert } from '../../utils/helper'
import SkipIcon from '../../assets/skip-ad-arrow.svg'
import useAdvertisementHook from './advertisementHook'

const CustomizedVideoJs = (props) => {
  const { t } = useTranslation()
  const { options, onReady, controlBar, adSetData, playerData, contentInfo } = props

  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)

  const [showSettingMenu, setShowSettingMenu] = useState(false)

  const {
    playPreRoll,
    playMidRoll,
    playPostRoll,
    playCustomRoll,
    currentPlayingAd,
    getStyleValue,
    handleRedirect,
    positionConfig,
    handleSkip,
    adDuration,
    skipSeconds,
  } = useAdvertisementHook()

  const [duration, setDuration] = useState(null)
  const [currentTime, setCurrentTime] = useState(null)
  const [bufferPercent, setBufferPercent] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [volume, setVolume] = useState(100)
  const [isMute, setIsMute] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isPIPMode, setIsPIPMode] = useState(false)
  const [seekBarHoverPercent, setSeekBarHoverPercent] = useState(0)
  const [showControlBar, setShowControlBar] = useState(false)
  const [showDisplayIcons, setShowDisplayIcons] = useState(false)
  const [cursorOverControlBar, setCursorOverControlBar] = useState(false)

  React.useEffect(() => {
    videojs.registerPlugin('qualityLevels', qualityLevels)
    videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector)
    videojs.registerPlugin('ads', ads)
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return
      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player)
        handlePlayerReady(player)
        setShowControlBar(true)
        setShowDisplayIcons(true)
      }))

      if (!isEmpty(adSetData)) {
        player.ads()
      }

      player.on('contentchange', () => {
        player.trigger('adsready')
      })

      //   player.controls(false)
      for (let i = 0; i < adSetData.length; i++) {
        const adInfo = adSetData[i]

        if (adInfo.ad_location_type === 'pre') {
          playPreRoll(adInfo, playerRef, setShowSettingMenu)
        }
        if (adInfo.ad_location_type === 'post') {
          playPostRoll(adInfo, playerRef, setShowSettingMenu)
        }
        if (adInfo.ad_location_type === 'mid') {
          playMidRoll(adInfo, playerRef, setShowSettingMenu)
        }
        if (adInfo.ad_location_type === 'custom') {
          playCustomRoll(adInfo, playerRef, setShowSettingMenu)
        }
      }

      player.trigger('adsready')
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef, adSetData])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  const handlePlayerReady = (player) => {
    playerRef.current = player

    player.on('waiting', () => {
      console.log('player is waiting')
    })

    player.on('ready', () => {
      player.playsinline(true)
      player.volume(localStorage.getItem('volume') ? localStorage.getItem('volume') : 1)
    })

    player.on('playing', async () => {
      if (isMobile) {
        player.requestFullscreen()
      }
      localStorage.setItem('volume', player.volume())
      setDuration(Math.floor(player.duration()))
      setIsPlaying(true)
    })

    player.on('play', async () => {
      setIsPlaying(true)
    })

    player.on('pause', async () => {
      setIsPlaying(false)
    })

    player.on('timeupdate', function () {
      setCurrentTime(Math.floor(player.currentTime()))
    })

    player.on('fullscreenchange', () => {
      if (player.isFullscreen()) {
        setIsFullScreen(true)
        return
      }
      setIsFullScreen(false)
    })

    player.on('progress', function () {
      const buffPercentage = player.bufferedPercent()
      // console.log('Buffer Percentage: ', buffPercentage * 100)
      setBufferPercent(buffPercentage * 100)
    })

    player.on('enterpictureinpicture', () => {
      setIsPIPMode(true)
    })

    player.on('leavepictureinpicture', () => {
      setIsPIPMode(false)
    })

    player.on('volumechange', () => {
      setVolume(player.volume() * 100)
      localStorage.setItem('volume', player.volume())
    })

    player.on('seeked', async () => {})

    player.on('dispose', async () => {})

    player.on('ended', async () => {})

    player.on('error', () => {})
  }

  const handleSeek = (event) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    let sec = (event.nativeEvent.offsetX / event.target.parentNode.clientWidth) * player.duration()

    player.currentTime(sec)
  }

  const handleSeekOver = (event) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    setSeekBarHoverPercent(event.nativeEvent.offsetX / event.target.parentNode.clientWidth)
  }

  const togglePlayPause = (isPlay) => {
    let player = playerRef.current

    if (!player || !isEmpty(currentPlayingAd)) {
      return
    }
    isPlay ? player.play() : player.pause()
  }

  const toggleFullScreen = (isFull) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    isFull ? player.requestFullscreen() : player.exitFullscreen()
  }

  const togglePIPMode = (isPIP) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    isPIP ? player.requestPictureInPicture() : player.exitPictureInPicture()
  }

  const toggleVolume = (value) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    setIsMute(false)
    player.muted(false)
    player.volume(value / 100)
  }

  const toggleMuted = (status) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    player.volume(status ? 0 : 100)
    player.muted(status)
    setIsMute(status)
  }

  const handleKeyPress = (e) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    if (e.keyCode === 39) {
      player.currentTime(player.currentTime() + 10)
    }
    if (e.keyCode === 37) {
      player.currentTime(player.currentTime() - 10)
    }

    if (e.keyCode === 38) {
      player.volume(player.volume() < 1 ? player.volume() + 0.1 : 1)
    }
    if (e.keyCode === 40) {
      player.volume(player.volume() > 0 ? player.volume() - 0.1 : 0)
    }
  }

  useInterval(
    async () => {
      if (isPlaying && !showSettingMenu && !cursorOverControlBar) {
        setShowControlBar(false)
      }
    },
    !playerData?.control_bar_always_visible && showControlBar ? 4000 : null,
  )

  useInterval(
    async () => {
      if (isPlaying && !showSettingMenu && !cursorOverControlBar) {
        setShowDisplayIcons(false)
      }
    },
    showDisplayIcons ? 4000 : null,
  )

  const forwardClick = () => {
    let player = playerRef.current
    if (!player) {
      return
    }

    player.currentTime(player.currentTime() + 10)
  }
  const backwardClick = () => {
    let player = playerRef.current
    if (!player) {
      return
    }
    player.currentTime(player.currentTime() - 10)
  }

  return (
    <div
      data-vjs-player=""
      className="relative"
      onKeyDown={handleKeyPress}
      tabIndex={-1}
      onMouseMove={() => {
        setShowDisplayIcons(true)
        setShowControlBar(true)
      }}
    >
      <div
        style={{
          //   border: '1px solid red',
          height: 'calc(100%)',
          display: !isEmpty(currentPlayingAd) ? 'flex' : 'none',
        }}
        className="w-full relative flex-col py-4 pl-4 pr-7 z-30"
      >
        {currentPlayingAd?.button?.is_enabled &&
          !currentPlayingAd?.button?.position.includes('BOTTOM') && (
            <div
              className={`w-full h-full flex ${positionConfig[currentPlayingAd?.button?.position]}`}
            >
              <button
                style={{
                  fontSize: '12px',
                  backgroundColor: getStyleValue('button_background', 'color', '#ffd91d'),
                  color: getStyleValue('button_text', 'color', '#000'),
                  fontFamily: getStyleValue('button_text', 'font', ''),
                  textAlign: getStyleValue('button_text', 'alignment', ''),
                  height: '34px',
                }}
                className={`border rounded-full px-4 ${getStyleValue(
                  'button_text',
                  'font_format',
                  '',
                )}`}
                onClick={() => handleRedirect(currentPlayingAd?.button?.link)}
              >
                {currentPlayingAd?.button?.text}
              </button>
            </div>
          )}
        <div
          style={{ marginTop: 'auto', fontSize: '12px' }}
          className="flex justify-between items-center relative"
        >
          {currentPlayingAd?.button?.is_enabled &&
            currentPlayingAd?.button?.position.includes('BOTTOM') && (
              <button
                style={{
                  fontSize: '12px',
                  background: '#ffd91d',
                  height: '34px',
                  zIndex: 20,
                  //   width: 'fit-content',
                  color: '#000',
                  ...positionConfig[currentPlayingAd?.button?.position],
                }}
                className={`border rounded-full px-4 absolute w-auto ${
                  currentPlayingAd?.button?.position === 'BOTTOM_RIGHT'
                    ? currentPlayingAd?.is_skip_allowed
                      ? 'right-[100px]'
                      : 'right-0'
                    : ''
                }`}
                onClick={() => handleRedirect(currentPlayingAd?.button?.link)}
              >
                {currentPlayingAd?.button?.text}
              </button>
            )}
          <div className="flex items-center">
            <p className="">{`Ad `}</p>
            <div
              style={{
                width: '3px',
                height: '3px',
                background: '#fff',
                borderRadius: '12px',
                marginLeft: '3px',
                marginRight: '3px',
              }}
            />
            <p>{AdSecondsConvert(adDuration)}</p>
          </div>

          {currentPlayingAd?.is_skip_allowed && (
            <>
              {skipSeconds > 0 ? (
                <div
                  style={{
                    background: '#00000080',
                    borderRadius: '17px',
                    fontSize: '10px',
                    height: '34px',
                  }}
                  className="px-4 flex justify-center items-center cursor-pointer"
                >
                  <p className="px-1 mr-1" style={{ borderRight: '0.5px solid #e3e3e3' }}>
                    {t('skip_after')}
                  </p>
                  <p>{skipSeconds}</p>
                </div>
              ) : (
                <div
                  style={{
                    background: '#fff',
                    borderRadius: '17px',
                    fontSize: '10px',
                    height: '34px',
                  }}
                  className="px-4 flex text-center justify-center items-center text-black cursor-pointer"
                  onClick={() => handleSkip(playerRef)}
                >
                  <p className="px-1 mr-1">{t('skip_ad')}</p>
                  <img
                    src={SkipIcon}
                    alt="SkipIcon"
                    style={{
                      width: '9.8px',
                      height: '13px',
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {isEmpty(currentPlayingAd) && controlBar && duration && (
        <HeaderBar
          playerData={playerData}
          contentInfo={contentInfo}
          showDisplayIcons={showDisplayIcons}
        />
      )}
      <div
        style={{ height: 'calc(100% - 102.5px)', marginTop: '46px' }}
        className="text-white w-full absolute top-0 z-20 flex justify-center items-center"
        onClick={controlBar ? () => togglePlayPause(!isPlaying) : null}
      >
        <PlayerLogo playerData={playerData} isPlaying={isPlaying} />
        <PlayerWatermark playerData={playerData} isPlaying={isPlaying} />
        <DisplayControls
          isPlaying={isPlaying}
          playerData={playerData}
          forwardClick={forwardClick}
          backwardClick={backwardClick}
          showDisplayIcons={showDisplayIcons}
          isAdPlaying={!isEmpty(currentPlayingAd)}
        />
      </div>

      <video
        ref={videoRef}
        className={'video-js-custom vjs-fill vjs-big-play-centered vjs-theme-fantasy-custom'}
      />

      {isEmpty(currentPlayingAd) && controlBar && duration && (
        <div
          style={{
            height: showControlBar ? '56.5px' : '0px',
            // backgroundColor: '#00000080',
            transition: 'height 0.25s ease-in',
            padding: '0px 20px',
          }}
          className="text-white w-full absolute bottom-0 "
          onMouseOver={() => setCursorOverControlBar(true)}
          onMouseLeave={() => setCursorOverControlBar(false)}
        >
          <div className="parent-div relative w-full">
            <SeekBar
              handleSeek={handleSeek}
              handleSeekOver={handleSeekOver}
              convertVideoSecondstoMintues={convertVideoSecondstoMintues}
              seekBarHoverPercent={seekBarHoverPercent}
              duration={duration}
              currentTime={currentTime}
              bufferPercent={bufferPercent}
              playerData={playerData}
              showControlBar={showControlBar}
            />
          </div>
          <div className="w-full flex justify-between items-center mt-2 relative">
            <VolumeBar
              toggleMuted={toggleMuted}
              volume={volume}
              toggleVolume={toggleVolume}
              isMute={isMute}
              playerData={playerData}
            />
            <PlayMenu
              isPlaying={isPlaying}
              togglePlayPause={togglePlayPause}
              currentTime={currentTime}
              duration={duration}
              playerData={playerData}
              forwardClick={forwardClick}
              backwardClick={backwardClick}
            />

            <SettingBar
              playerData={playerData}
              isPIPMode={isPIPMode}
              togglePIPMode={togglePIPMode}
              isFullScreen={isFullScreen}
              toggleFullScreen={toggleFullScreen}
              showSettingMenu={showSettingMenu}
              setShowSettingMenu={setShowSettingMenu}
              playerRef={playerRef}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomizedVideoJs
